import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "pagination-style",
    key: this.page
  }, [
    _createElementVNode("button", {
      disabled: this.disablePrevious,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previusPage && _ctx.previusPage(...args)))
    }, "Anterior", 8, _hoisted_1),
    _createElementVNode("span", null, "Página " + _toDisplayString(this.page) + " de " + _toDisplayString(this.lastPage), 1),
    _createElementVNode("button", {
      disabled: this.disableNext,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
    }, "Próxima", 8, _hoisted_2)
  ]))
}