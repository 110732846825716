<template>
  <div class="manage-itens">
    <div class="header-manage-itens">
      <h2>Gerenciar itens de alimentação</h2>
      <button @click="openDrawerComponent">
        Cadastrar itens de alimentação
      </button>
    </div>
    <form v-if="this.units.results.length > 0" @submit.prevent="handleSubmit">
      <div>
        <div class="select">
          <label>unidade</label>
          <select required v-model="this.form.unit">
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(unit, index) in this.units.results"
              :key="index"
              :value="unit"
            >
              {{ unit.nome }}
            </option>
          </select>
        </div>

        <div class="select">
          <label>tipo</label>
          <select required v-model="this.form.item">
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(item, index) in this.foodItens.results"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>

      <!-- <Button :name="'Buscar'" :type="'submit'" :disabled="this.search" /> -->
    </form>

    <div v-if="this.search === true">
      <Loading />
    </div>

    <div v-if="this.foodItensResponse?.results && this.search === false">
      <h4>Resultados</h4>
      <div class="table-food-items">
        <div v-if="this.foodItensResponse?.results.length > 0">
          <Table :columns="this.thead">
            <tr
              v-for="(item, index) in this.foodItensResponse.results"
              :key="index"
              @click="editItem(item)"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.unidade }}</td>
              <td>R$ {{ item.value_single }}</td>
              <td>R$ {{ item.value_package }}</td>
              <td>{{ changeDate(item.initial_date) }}</td>
              <td>{{ changeDate(item.final_date) }}</td>
            </tr>
          </Table>
        </div>
        <h4
          class="no-results"
          v-if="this.foodItensResponse?.results.length === 0"
        >
          Nenhum resultado encontrado
        </h4>
      </div>
    </div>

    <Drawer
      ref="drawerRef"
      @drawer-closed="resetForm"
      :drawerName="this.edit ? this.formDrawer.item : this.text"
    >
      <form class="drawer-style" @submit.prevent="handleSubmitDrawer">
        <div class="select" :key="componentKey">
          <label>tipo</label>
          <select
            :disabled="this.inValidity"
            required
            v-model="this.formDrawer.item"
          >
            <option value="">Selecione...</option>
            <option
              v-for="(option, index) in this.foodItens.results"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>

        <div class="select">
          <label>unidade</label>
          <select
            :disabled="this.inValidity"
            required
            v-model="this.formDrawer.unit"
          >
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(unit, index) in this.units.results"
              :key="index"
              :value="unit"
            >
              {{ unit.nome }}
            </option>
          </select>
        </div>

        <div class="input-text">
          <label>Preço avulso</label>
          <input
            :disabled="this.inValidity"
            required
            maxlength="5"
            type="text"
            id="inputText"
            v-model="this.formDrawer.single_value"
            :placeholder="'Insira o valor'"
            @input="appyMask('single')"
          />
        </div>

        <div class="input-text">
          <label>Preço Pacote</label>
          <input
            :disabled="this.inValidity"
            required
            maxlength="5"
            type="text"
            id="inputText"
            v-model="this.formDrawer.package_value"
            :placeholder="'Insira o valor'"
            @input="appyMask('package')"
          />
        </div>

        <div>
          <InputDate
            :disabled="this.inValidity"
            :label="'Data inicio'"
            :value="this.formDrawer.initial_date"
            :selectedDate="this.formDrawer.initial_date"
            @update:selectedDate="handleUpdate($event, 'drawerInitialDate')"
          />

          <InputDate
            :disabled="this.outOfValidity"
            :label="'Data final'"
            :value="this.formDrawer.final_date"
            :selectedDate="this.formDrawer.final_date"
            @update:selectedDate="handleUpdate($event, 'drawerFinalDate')"
          />
        </div>

        <Button
          :type="'submit'"
          :name="'Salvar'"
          :disabled="this.search || this.outOfValidity"
        />
      </form>
    </Drawer>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent, ref } from "vue";
// Components
import Button from "@/components/Button.vue";
import Table from "@/components/Table.vue";
import Drawer from "@/components/Drawer.vue";
import InputDate from "@/components/InputDate.vue";
import Loading from "@/components/Loading.vue";
// Vuex
import { mapState } from "vuex";
import store from "@/store";
// API
import { FoodAPI } from "@/services/api";
// Helpers
import { standardDate, formatMoneyInt } from "@/helpers/masks";
// Libs
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

interface IFormDrawer {
  item?: string;
  unit?: {
    codcoligada?: number;
    codfilial?: number;
    nome?: string;
  };

  nome?: string;
  single_value?: string;
  package_value?: string;
  initial_date?: string;
  final_date?: string;
}

interface IFoodItem {
  id: number;
  created_at: string;
  description: string | null;
  final_date: string;
  value_package: number;
  codcoligada: number;
  codfilial: number;
  name: string;
  actived: boolean;
  unidade: string;
  initial_date: string;
  value_single: number;
}

interface IFoodItensResponse {
  count: number;
  results: IFoodItem[];
}

interface IManageFoodItens {
  form: {
    unit?: {
      codcoligada: string | null;
      codfilial: string | null;
      nome: string | null;
    };
    item?: string | null;
  };
  search: boolean;
  edit: boolean;
  thead: string[];
  foodItensResponse?: IFoodItensResponse;
  text: string;
  formDrawer: IFormDrawer;
  idEditItem?: number;
  componentKey: number;
  inValidity: boolean;
  outOfValidity: boolean;
  saveData: number;
}

interface IForm {
  codcoligada: string;
  codfilial: string;
  type_item: string;

  [key: string]: string;
}

export default defineComponent({
  name: "ManageFoodItens",
  components: {
    Button,
    Table,
    Drawer,
    InputDate,
    Loading,
  },

  data(): IManageFoodItens {
    return {
      thead: [
        "Nome",
        "Unidade",
        "Valor avulso",
        "Valor pacote",
        "Início da vigência",
        "Fim da vigência",
      ],
      form: {
        unit: undefined,
        item: undefined,
      },

      search: false,
      edit: false,

      foodItensResponse: undefined,
      text: "Criar item de alimentação",

      formDrawer: {
        item: "",
        unit: undefined,
        single_value: undefined,
        package_value: undefined,
        initial_date: undefined,
        final_date: undefined,
      },
      idEditItem: 0,
      componentKey: 0,

      inValidity: false,
      outOfValidity: false,
      saveData: 0,
    };
  },

  computed: mapState(["units", "foodItens", "loadAccess"]),

  mounted() {
    store.dispatch("fetchUnitsData");
    store.dispatch("fetchFoodItensData");
    store.dispatch("fetchLoadAccess");
  },

  setup() {
    const drawerRef = ref<typeof Drawer>();

    const openDrawer = () => {
      drawerRef.value?.openDrawer();
    };

    return { drawerRef, openDrawer };
  },

  methods: {
    handleUpdate(value: string, inputUpdate: string) {
      if (inputUpdate === "drawerInitialDate") {
        this.formDrawer.initial_date = value;
      } else if (inputUpdate === "drawerFinalDate") {
        this.formDrawer.final_date = value;
      }
    },

    isBeforeOrEqualToday(date: string) {
      const inputDate = new Date(date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return inputDate <= today;
    },

    openDrawerComponent() {
      this.openDrawer();
    },

    moneyFormat(value: string) {
      return formatMoneyInt(value);
    },

    editItem(values: IFoodItem) {
      this.edit = true;

      this.idEditItem = values.id;

      this.formDrawer = {
        item: values.name,
        unit: {
          codcoligada: values.codcoligada,
          codfilial: values.codfilial,
          nome: values.unidade,
        },

        single_value: values.value_single?.toString(),
        package_value: values.value_package?.toString(),
        initial_date: values.initial_date,
        final_date: values.final_date,
      };

      this.inValidity = this.isBeforeOrEqualToday(values.initial_date);
      this.outOfValidity = this.isBeforeOrEqualToday(values.final_date);

      this.openDrawerComponent();
    },

    changeDate(value: string) {
      return standardDate(value);
    },

    handleSubmit() {
      if (
        this.form.unit &&
        this.form.unit.codcoligada &&
        this.form.unit.codfilial &&
        this.form.item
      ) {
        this.search = true;

        const filters: IForm = {
          codcoligada: this.form.unit.codcoligada,
          codfilial: this.form.unit.codfilial,
          type_item: this.form.item,
        };
        const queryString = Object.keys(filters)
          .map(
            (key: string) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
          )
          .join("&");

        const url = "food/food_items/";

        const fullUrl = queryString ? `${url}?${queryString}` : url;

        FoodAPI.get(fullUrl, {
          headers: {
            "alimentacao-api-key": `${process.env.GET_FOOD_ITEMS}`,
            authorization: `bearer ${JSON.parse(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              localStorage.getItem("access")!
            )}`,
          },
        })
          .then((response) => {
            this.foodItensResponse = response.data;
            this.search = false;
          })
          .catch((error) => {
            toast.error("Erro na requisição");
            this.search = false;
            console.error("Erro na requisição:", error);
          });
      } else {
        toast.warning("Existem dados pendentes");
      }
    },

    mask(value: string) {
      if (value === "" || value === undefined) {
        return "";
      }

      if (value === "single" && this.formDrawer.single_value) {
        const numericValue = this.formDrawer.single_value.replace(/\D/g, "");
        const limitedValue = numericValue.substring(0, 11);

        return limitedValue;
      } else if (this.formDrawer.package_value) {
        const numericValue = this.formDrawer.package_value.replace(/\D/g, "");
        const limitedValue = numericValue.substring(0, 11);

        return limitedValue;
      }
    },

    appyMask(value: string) {
      const result = this?.mask(value)
        ?.replace(/\D/g, "")
        .replace(/(\d{1,2})$/, `.$1`)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.00");

      if (value === "single") {
        return (this.formDrawer.single_value = result);
      } else {
        return (this.formDrawer.package_value = result);
      }
    },

    resetForm() {
      (this.formDrawer = {
        item: "",
        unit: undefined,
        nome: undefined,
        single_value: undefined,
        package_value: undefined,
        initial_date: undefined,
        final_date: undefined,
      }),
        (this.componentKey += 1);
      this.edit = false;
      this.inValidity = false;
      this.outOfValidity = false;
    },

    handleSubmitDrawer() {
      if (
        this.formDrawer.item &&
        this.formDrawer.unit?.codcoligada &&
        this.formDrawer.unit?.codfilial &&
        this.formDrawer.single_value &&
        this.formDrawer.package_value &&
        this.formDrawer.initial_date &&
        this.formDrawer.final_date
      ) {
        if (this.edit) {
          this.search = true;

          FoodAPI.patch(
            `food/food_items/${this.idEditItem}`,
            {
              name: this.formDrawer.item,
              codcoligada: this.formDrawer.unit.codcoligada,
              codfilial: this.formDrawer.unit.codfilial,
              value_single: this.formDrawer.single_value,
              value_package: this.formDrawer.package_value,
              initial_date: this.formDrawer.initial_date,
              final_date: this.formDrawer.final_date,
            },
            {
              headers: {
                "alimentacao-api-key": `${process.env.PATCH_FOOD_ITEMS}`,
                authorization: `bearer ${JSON.parse(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  localStorage.getItem("access")!
                )}`,
              },
            }
          )
            .then((response) => {
              if (response) {
                this.saveData += 1;
                this.search = false;
                this.resetForm();
                toast.success("Item alterado com sucesso!");
              }
            })
            .catch((error) => {
              toast.error(
                error.response.data.detail
                  ? error.response.data.detail
                  : "Erro na requisição"
              );
              this.search = false;
              console.error("Erro na requisição:", error);
            });
        } else {
          this.search = true;

          FoodAPI.post(
            "food/food_items/",
            {
              name: this.formDrawer.item,
              codcoligada: this.formDrawer.unit.codcoligada,
              codfilial: this.formDrawer.unit.codfilial,
              value_single: this.formDrawer.single_value,
              value_package: this.formDrawer.package_value,
              initial_date: this.formDrawer.initial_date,
              final_date: this.formDrawer.final_date,
            },
            {
              headers: {
                "alimentacao-api-key": `${process.env.POST_FOOD_ITEMS}`,
                authorization: `bearer ${JSON.parse(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  localStorage.getItem("access")!
                )}`,
              },
            }
          )
            .then((response) => {
              if (response) {
                this.saveData += 1;
                this.search = false;
                this.resetForm();
                toast.success("Item criado com sucesso!");
              }
            })
            .catch((error) => {
              toast.error(
                error.response.data.detail
                  ? error.response.data.detail
                  : "Erro na requisição"
              );
              this.search = false;
              console.error("Erro na requisição:", error);
            });
        }
      } else {
        alert("Existem dados pendentes");
      }
    },
  },

  watch: {
    saveData: {
      handler() {
        if (
          this.form.unit &&
          this.form.unit.codcoligada &&
          this.form.unit.codfilial &&
          this.form.item
        ) {
          this.handleSubmit();
        }
      },
      immediate: true,
    },
    "form.unit": {
      handler() {
        if (this.form.unit && this.form.item) {
          this.handleSubmit();
        }
      },
      immediate: true,
    },
    "form.item": {
      handler() {
        if (this.form.unit && this.form.item) {
          this.handleSubmit();
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss">
.manage-itens {
  width: 1280px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .header-manage-itens {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: none;
      color: $purple;

      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    button {
      height: 37px;
      color: $purple;
      padding: 4px 8px;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      border: none;
      text-transform: uppercase;
      background-color: transparent;

      cursor: pointer;
    }
    button:hover {
      background-color: $purple;
      color: #fff;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 8px 0;

    div {
      display: flex;
      gap: 0 8px;

      div {
        width: 100%;
      }
    }

    button {
      margin-left: auto;
    }
  }

  .table-food-items {
    width: 100%;
  }

  .table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .drawer-style {
    display: flex;
    flex-direction: column;
    gap: 8px;

    height: 100%;

    div {
      display: flex;
      gap: 0 8px;
      width: 100%;
    }

    button {
      margin-left: auto;
      margin-top: auto;
    }

    .input-text {
      display: flex;
      flex-direction: column;

      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      input {
        height: 25px;

        border: none;
        border: 2px solid $light_grey;

        color: $grey;

        font-size: 14px;
        padding: 4px;

        outline: none;

        &:disabled {
          background-color: $light_grey;
          color: $light_grey3;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .manage-itens {
    width: 960px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .manage-itens {
    width: 750px;
  }
}

@media (max-width: 425px) {
  .manage-itens {
    width: 320px;

    flex-direction: column;

    .header-manage-itens {
      flex-direction: column;

      h2 {
        font-size: 36px;
      }
    }

    form {
      div {
        flex-direction: column;
      }
    }

    .table {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}

@media (max-width: 425px) {
  .manage-itens {
    width: 300px;
  }
}
</style>
