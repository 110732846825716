<template>
  <div class="table">
    <table>
      <thead>
        <tr>
          <th v-for="(thead, index) in this.columns" :key="index">
            {{ thead }}
            <button
              v-if="hasIcon(thead)"
              type="button"
              @click="
                toggleValue(this.iconsTrue.find((icon) => icon.name === thead))
              "
            >
              <img
                :class="{
                  rotate: this.iconsTrue.find(
                    (icon) => icon.name === thead && icon.value === true
                  ),
                }"
                src="@/assets/icons/icon_arrow_table.svg"
                alt="arrow"
              />
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface ITableData {
  iconsTrue?: { id: number; name: string; value: boolean }[];
}

export default defineComponent({
  name: "TableComponent",

  props: {
    icons: { type: Array, required: false },
    columns: { type: Array, required: true },
  },

  data(): ITableData {
    return {
      iconsTrue: [],
    };
  },

  mounted() {
    this.columns;
    if (this.icons) {
      this.iconsTrue = this.icons.map((name, index) => {
        return { id: index + 1, name: name, value: false } as {
          id: number;
          name: string;
          value: boolean;
        };
      });
    }
  },

  methods: {
    hasIcon(thead: string) {
      if (this.icons) {
        const icon = this.icons.find((icon) => icon === thead);
        return icon;
      }
    },

    toggleValue(values: { id: number; name: string; value: boolean }) {
      if (this.iconsTrue) {
        this.iconsTrue[values.id - 1].value =
          !this.iconsTrue[values.id - 1].value;

        this.$emit('changeSequence', values);
      }
    },
  },
});
</script>

<style lang="scss">
.table {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      border-bottom: 2px solid $light_grey;
      tr {
        th {
          text-align: start;
          padding: 8px 16px;

          white-space: nowrap;

          button {
            cursor: pointer;

            border: none;
            background-color: transparent;

            img {
              padding: 3px;

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }

          div {
            display: flex;
            flex-wrap: nowrap;
          }
        }
      }
    }

    tbody {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      tr {
        td {
          border-bottom: 1px solid $light_grey;
          text-align: start;
          padding: 8px 16px;

          span {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
