import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/icon_close.svg'


const _hoisted_1 = {
  key: 0,
  class: "drawer"
}
const _hoisted_2 = { class: "header-drawer" }
const _hoisted_3 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.drawer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["drawer-container", { 'slide-content': !this.drawer, hidden: this.drawer }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.drawerName), 1),
            _createElementVNode("img", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args))),
              src: _imports_0,
              alt: "close-drawer"
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}