<template>
  <div class="home">
    <AcessButtonOut
      :label="'Relatórios de alimentação'"
      :link="'https://lookerstudio.google.com/s/o-_CnzRGcOA'"
    />
    <AcessButton
      :label="'Gerenciar itens de alimentação'"
      :link="'/gerenciar-itens-de-alimentacao'"
    />
    <AcessButton
      :label="'Cadastro de dias úteis'"
      :link="'/cadastro-de-dias-uteis'"
    />
    <AcessButton
      :label="'Adicionar Crédito para Alimentação'"
      :link="'/adicionar-credito-alimentacao'"
    />
    <AcessButton :label="'Mudança de senha'" :link="'/resetar-senha'" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AcessButton from "../components/AcessButton.vue";
import AcessButtonOut from "../components/AcessButtonOut.vue";
import { mapState } from "vuex";
import store from "@/store";

export default defineComponent({
  name: "HomeView",
  components: {
    AcessButton,
    AcessButtonOut,
  },

  computed: mapState(["units"]),

  mounted() {
    store.dispatch("fetchUnitsData");
  },
});
</script>

<style lang="scss">
.home {
  width: 960px;
  padding: 8px;

  display: flex;
  flex-wrap: wrap;

  gap: 8px;
}

@media (max-width: 425px) {
  .home {
    width: 320px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
