import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/icons/icon_arrow_table.svg'


const _hoisted_1 = { class: "table" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.columns, (thead, index) => {
            return (_openBlock(), _createElementBlock("th", { key: index }, [
              _createTextVNode(_toDisplayString(thead) + " ", 1),
              (_ctx.hasIcon(thead))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    onClick: ($event: any) => (
                _ctx.toggleValue(this.iconsTrue.find((icon) => icon.name === thead))
              )
                  }, [
                    _createElementVNode("img", {
                      class: _normalizeClass({
                  rotate: this.iconsTrue.find(
                    (icon) => icon.name === thead && icon.value === true
                  ),
                }),
                      src: _imports_0,
                      alt: "arrow"
                    }, null, 2)
                  ], 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}