<template>
  <div class="header">
    <router-link to="/">
      <img src="@/assets/icons/icon_escola.svg" alt="acess" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderApp",
});
</script>

<style lang="scss">
.header {
  height: 47px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  color: white;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin-top: 10px;
    img {
      height: 30px;
      width: 30px;
    }
  }
}
</style>
