<template>
  <div class="input-date">
    <label>{{ this.label }}</label>
    <div :class="{ disabled: this.disabled }">
      <img src="@/assets/icons/icon_calendar.svg" alt="calendar" />
      <input
        type="date"
        id="dateInput"
        v-model="internalValue"
        @change="emitSelectedDate"
      />
      <span>{{
        this.value ? this.value.split("-").reverse().join("/") : "Selecione..."
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputDate",
  props: {
    label: { type: String, required: true },
    value: { type: String, required: false },
    disabled: { type: Boolean, required: false },
  },
  mounted() {
    this.label;
    this.value;
    this.disabled;
  },

  data() {
    return {
      internalValue: this.value,
    };
  },
  methods: {
    emitSelectedDate() {
      this.$emit("update:selectedDate", this.internalValue);
    },
  },
});
</script>

<style lang="scss">
.input-date {
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  div {
    &.disabled {
      background-color: $light_grey;
      color: $light_grey3;
      cursor: not-allowed;
    }

    height: 33px;
    width: 100%;

    border: 2px solid $light_grey;

    display: flex;
    align-items: center;
    gap: 0 8px;

    position: relative;
    overflow: hidden;

    img {
      margin-left: 8px;

      width: 15.75px;
      height: 17.5px;
    }

    input {
      border: none;
      font-size: 14px;

      height: 37px;
      width: 100%;

      margin: 0;
      position: absolute;
      opacity: 0;
    }

    input::-webkit-calendar-picker-indicator {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    span {
      font-size: 14px;
      width: 100%;
    }
  }
}

@media (max-width: 320px) {
  .input-date {
    width: 300px;
  }
}
</style>
