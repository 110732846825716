<template>
  <a
    class="acess-button"
    :href="this.link"
    target="_blank"
    rel="noreferrer"
  >
    <label>{{ this.label }}</label>
    <div>
      <img
        v-if="this.target"
        src="@/assets/icons/icon_arrow_circle_right.svg"
        alt="acess"
      />
      <span>Acessar</span>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AcessButtonOut",

  props: {
    label: { type: String, required: true },
    link: { type: String, required: true },
  },
  mounted() {
    this.label;
    this.link;
  },
});
</script>

<style lang="scss">
.acess-button {
  width: 228px;
  height: 80px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 24px;
  background: var(--primary-roxo-light-90, $light_purple);

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  color: $grey;
  text-decoration: none;

  cursor: pointer;

  label {
    cursor: pointer;
  }

  div {
    display: none;
  }
}
.acess-button:hover {
  background-color: $purple;
  color: #fff;

  div {
    display: flex;
    gap: 0 4px;

    align-items: flex-end;
    justify-content: flex-end;

    margin-right: 4px;
    margin-top: auto;
  }
}
</style>
