import { createStore } from "vuex";
import { IYears, IUnits, IFoodItens } from "@/interfaces/utils";
import { FoodAPI } from "@/services/api";

interface RootState {
  years: IYears;
  units: IUnits;
  foodItens: IFoodItens;
  access: string;
}

export default createStore<RootState>({
  state: {
    years: {
      results: [],
    },
    units: {
      count: 0,
      results: [],
    },
    foodItens: {
      results: [],
    },
    access: "",
  },
  getters: {},
  mutations: {
    loadYears(state, data) {
      state.years = data;
    },
    loadUnits(state, data) {
      state.units = data;
    },
    loadFoodItens(state, data) {
      state.foodItens = data;
    },

    saveAccess(state, access) {
      state.access = access;
      localStorage.setItem("access", JSON.stringify(state.access));
    },

    loadAccess(state, access) {
      state.access = access;
    },
  },
  actions: {
    async fetchYearsData({ commit }) {
      try {
        const response = await FoodAPI.get("food/active-years/", {
          headers: {
            "alimentacao-api-key": `${process.env.GET_ACTIVE_YEARS}`,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            "authorization": `bearer ${JSON.parse(localStorage.getItem("access")!)}`
          },
        });
        commit("loadYears", response.data);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    },

    async fetchUnitsData({ commit }) {
      try {
        const response = await FoodAPI.get("units/", {
          headers: {
            "alimentacao-api-key": `${process.env.GET_UNITS}`,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            "authorization":  `bearer ${JSON.parse(localStorage.getItem("access")!)}`
          },
        });
        commit("loadUnits", response.data);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    },

    async fetchFoodItensData({ commit }) {
      try {
        const response = await FoodAPI.get("food/food_types/", {
          headers: {
            "alimentacao-api-key": `${process.env.GET_FOOD_TYPES}`,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            "authorization": `bearer ${JSON.parse(localStorage.getItem("access")!)}`
          },
        });
        commit("loadFoodItens", response.data);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    },

    async saveAccess({ commit }, access) {
      try {
        commit("saveAccess", access);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchLoadAccess({ commit }) {
      if (localStorage.getItem("access")) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        commit("loadAccess", JSON.parse(localStorage.getItem("access")!));
      }
    }
  },
  modules: {},
});
