<template>
  <div class="registration-working-days">
    <h2>Cadastro de dias úteis</h2>

    <div v-if="this.units?.results && this.years.results">
      <div class="select">
        <label>unidade</label>
        <select required v-model="this.form.unit">
          <option :value="undefined">Selecione...</option>
          <option
            v-for="(unit, index) in this.units?.results"
            :key="index"
            :value="unit"
          >
            {{ unit.nome }}
          </option>
        </select>
      </div>

      <div class="select">
        <label>ano</label>
        <select required v-model="this.form.year">
          <option :value="null">Selecione...</option>
          <option
            v-for="(year, index) in this.years.results"
            :key="index"
            :value="year.value"
          >
            {{ year.label }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <div class="select">
        <label>mês</label>
        <select v-model="this.form.month">
          <option :value="null">Selecione...</option>
          <option
            v-for="(month, index) in this.months"
            :key="index"
            :value="month.value"
          >
            {{ month.label }}
          </option>
        </select>
      </div>
      <div class="input-text">
        <label>Dias úteis</label>
        <input
          maxlength="2"
          type="text"
          id="inputText"
          v-model="this.form.days"
          :placeholder="'Insira o numero de dias'"
        />
      </div>
    </div>

    <Button :name="'Salvar'" @click="handleSave" />

    <div v-if="this.search === true" class="loading">
      <Loading class="loading" />
    </div>

    <div
      v-if="this.responseSearch?.results.length > 0 && this.search === false"
      class="table"
      :key="saveData"
    >
      <h4>Resultados</h4>
      <div>
        <Table :columns="this.thead" :key="saveData">
          <tr
            v-for="(month, index) in this.responseSearch?.results"
            :key="index"
          >
            <td>{{ month.unidade }}</td>
            <td>{{ searchMonth(month.month.toString()) }}</td>
            <td>{{ month.year }}</td>
            <td>{{ month.days }}</td>
            <td>{{ extrairApenasData(month.updated_at) }}</td>
          </tr>
        </Table>
      </div>
    </div>

    <div v-if="this.responseSearch?.results.length === 0" class="table">
      <h4>Resultados</h4>
      <h4 class="no-results">Nenhum resultado encontrado</h4>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue";
// Components
import Button from "@/components/Button.vue";
import Table from "@/components/Table.vue";
import Loading from "@/components/Loading.vue";
// Vuex
import { mapState } from "vuex";
// Store
import store from "@/store";
// Helpers
import { MONTHS } from "@/helpers/helpers";
// Masks
import { FoodAPI } from "@/services/api";
import { standardDate } from "@/helpers/masks";
// Libs
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

interface IResponseSearch {
  count: number;
  results: {
    codcoligada: number;
    codfilial: number;
    days: number;
    month: number;
    unidade: string;
    updated_at: string;
    year: number;
  }[];
}

interface IFormSearch {
  codcoligada: string;
  codfilial: string;
  anoletivo: string;

  [key: string]: string;
}

interface IRegistrationWorkingDays {
  form: {
    unit?: {
      codcoligada: string;
      codfilial: string;
      nome: string;
    };
    year: string | null;
    month: string | null;
    days: string | null;
  };
  thead: string[];
  months: { label: string; value: string }[];
  responseSearch?: IResponseSearch[];
  saveData: number;
  search: boolean;
}

export default defineComponent({
  name: "RegistrationWorkingDays",

  components: {
    Button,
    Table,
    Loading,
  },

  data(): IRegistrationWorkingDays {
    return {
      form: {
        unit: undefined,
        year: null,
        month: null,
        days: null,
      },

      thead: [
        "Unidade",
        "Mês",
        "Ano",
        "Dias Úteis",
        "Data de Registro/Alteração",
      ],
      months: MONTHS,
      responseSearch: undefined,
      saveData: 0,
      search: false,
    };
  },

  computed: mapState(["years", "units", "loadAccess"]),

  mounted() {
    store.dispatch("fetchYearsData");
    store.dispatch("fetchUnitsData");
    store.dispatch("loadAccess");
  },

  methods: {
    handleSave() {
      if (
        this.form.unit?.codcoligada &&
        this.form.unit?.codfilial &&
        this.form.year &&
        this.form.month &&
        this.form.days
      ) {
        FoodAPI.post(
          "food/school-consumption-days/",
          {
            year: parseInt(this.form.year),
            days: parseInt(this.form.days),
            month: parseInt(this.form.month),
            codcoligada: parseInt(this.form.unit.codcoligada),
            codfilial: parseInt(this.form.unit.codfilial),
          },
          {
            headers: {
              "alimentacao-api-key": `${process.env.POST_CONSUMPTION_DAYS}`,
              authorization: `bearer ${JSON.parse(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                localStorage.getItem("access")!
              )}`,
            },
          }
        )
          .then((response) => {
            if (response) {
              this.saveData += 1;
              toast.success("Sucesso ao salvar os dias!");
            }
          })
          .catch((error) => {
            toast.error("Erro na requisição");
            console.error("Erro na requisição:", error);
          });
      } else {
        toast.warning("Verifique os dados do formulário");
      }
    },

    extrairApenasData(dataComplete: string) {
      if (dataComplete) {
        const split = dataComplete.split("T");
        const data = split[0];
        return standardDate(data);
      }
    },

    searchMonth(value: string) {
      const monthData = this.months.find((month) => month.value === value);
      return monthData?.label;
    },

    searchUnits() {
      if (
        this.form.unit?.codcoligada &&
        this.form.unit?.codfilial &&
        this.form.year
      ) {
        this.search = true;

        const filters: IFormSearch = {
          codcoligada: this.form.unit.codcoligada,
          codfilial: this.form.unit.codfilial,
          anoletivo: this.form.year,
        };
        const queryString = Object.keys(filters)
          .map(
            (key: string) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
          )
          .join("&");

        const url = "food/school-consumption-days/";

        const fullUrl = queryString ? `${url}?${queryString}` : url;

        FoodAPI.get(fullUrl, {
          headers: {
            "alimentacao-api-key": `${process.env.GET_CONSUMPTION_DAYS}`,
            authorization: `bearer ${JSON.parse(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              localStorage.getItem("access")!
            )}`,
          },
        })
          .then((response) => {
            this.responseSearch = response.data;
            this.search = false;
          })
          .catch((error) => {
            toast.error("Erro na requisição");
            this.search = true;
            console.error("Erro na requisição:", error);
          });
      }
    },
  },

  watch: {
    "form.unit": {
      handler() {
        this.searchUnits();
      },
      immediate: true,
    },
    "form.year": {
      handler() {
        this.searchUnits();
      },
      immediate: true,
    },
    saveData: {
      handler() {
        this.searchUnits();
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss">
.registration-working-days {
  width: 1280px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .loading {
    flex-direction: row;
  }

  h2 {
    margin: none;
    color: $purple;

    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  div {
    width: 100%;

    display: flex;
    gap: 0 8px;

    .input-text {
      display: flex;
      flex-direction: column;

      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      input {
        height: 25px;

        border: none;
        border: 2px solid $light_grey;

        color: $grey;

        font-size: 14px;
        padding: 4px;

        outline: none;

        &:disabled {
          background-color: $light_grey;
          color: $light_grey3;
          cursor: not-allowed;
        }
      }
    }
  }

  button {
    margin-left: auto;
  }

  .table {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .registration-working-days {
    width: 960px;
  }
}

@media (max-width: 768px) {
  .registration-working-days {
    width: 750px;
  }
}

@media (max-width: 425px) {
  .registration-working-days {
    width: 320px;
    div {
      flex-direction: column;
      gap: 4px;
    }

    .loading {
      flex-direction: row;
    }

    .table {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}
@media (max-width: 320px) {
  .registration-working-days {
    width: 310px;
  }
}
</style>
