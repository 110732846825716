<template>
  <div class="foodCredit">
    <h2>Adicionar crédito para alimentação</h2>
    <form class="form" @submit.prevent="fetchStudents(1)">
      <div class="input-text">
        <label>Aluno(a)</label>
        <input
          required
          type="text"
          id="inputText"
          v-model="this.searchInput"
          :placeholder="'Insira o Nome ou RA do Aluno'"
        />
      </div>

      <Button :type="'submit'" :name="'Buscar'" :disabled="this.loading" />
    </form>

    <h4 v-if="this.studentsResults">Resultados</h4>

    <div v-if="this.loading === true">
      <Loading />
    </div>

    <h4
      v-if="this.studentsResults?.count === 0 && this.loading !== true"
      class="no-results"
    >
      Nenhum resultado encontrado
    </h4>

    <div
      class="studentContainer"
      v-if="this.studentsResults?.count > 0 && this.loading !== true"
    >
      <div
        v-for="(student, index) in this.studentsResults?.results"
        :key="index"
        class="studentCard"
        @click="selectStudent(student)"
      >
        <h4>{{ student.nome_aluno }}</h4>
        <span><strong>RA</strong>: {{ student.estudante_ra }}</span>
        <span>
          <strong>Responsável</strong>: {{ student.nome_responsavel }}</span
        >
        <span><strong>Crédito atual</strong>: {{ student.valor_credito }}</span>
      </div>
    </div>

    <Pagination
      ref="paginationRef"
      v-if="this.studentsResults?.count > 20 && this.loading !== true"
      :disableNext="this.studentsResults.next ? false : true"
      :disablePrevious="this.studentsResults.previus ? false : true"
      :page="this.paginationProps.page"
      :lastPage="Math.ceil(this.studentsResults.count / 20)"
      @nextPage="fetchStudents(paginationProps.page + 1, 'next')"
      @previusPage="fetchStudents(paginationProps.page - 1, 'previus')"
    />

    <Drawer
      ref="drawerRef"
      @drawer-closed="resetForm"
      :drawerName="this.selectedStudent?.nome_aluno"
    >
      <form class="drawer-style" @submit.prevent="saveCredit">
        <h4>Preencha os campos abaixo.</h4>
        <div class="input-text">
          <label>Valor do crédito</label>
          <input
            required
            maxlength="6"
            type="text"
            id="inputText"
            v-model="this.drawerValues.valor_credito"
            :placeholder="'Insira o valor'"
            @input="appyMask('single')"
          />
        </div>

        <div class="input-text">
          <label>Motivo</label>
          <input
            required
            maxlength="100"
            type="text"
            id="inputText"
            v-model="this.drawerValues.motivo"
            :placeholder="'Insira o motivo do crédito'"
          />
        </div>

        <div>
          <Button
            :type="'submit'"
            :name="'Salvar'"
            :disabled="this.postLoading"
          />
        </div>
      </form>
    </Drawer>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent, ref } from "vue";
// Components
import Drawer from "@/components/Drawer.vue";
import Button from "@/components/Button.vue";
import Loading from "@/components/Loading.vue";
import Pagination from "@/components/Pagination.vue";
// Services
import { FoodAPI } from "@/services/api";
// Libs
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import store from "@/store";
import { mapState } from "vuex";

interface IStudent {
  nome_aluno: string;
  estudante_ra: number;
  nome_responsavel: string;
  cpf_responsavel: number;
  valor_credito: string;
  codfilial: number;
  codcoligada: number;
}

interface IGetStudents {
  next: null | string;
  previus: null | string;
  count: number;
  results: IStudent[];
}

interface IPostCredit {
  estudante_ra: number;
  valor_credito: string;
  motivo: string;
}

interface IData {
  studentsResults?: IGetStudents;
  searchInput: string;
  selectedStudent?: IStudent;
  drawerValues: IPostCredit;
  loading: boolean;
  postLoading: boolean;

  paginationProps: {
    page: number;
  };
}

export default defineComponent({
  name: "FoodCredit",

  components: {
    Drawer,
    Button,
    Loading,
    Pagination,
  },

  setup() {
    const drawerRef = ref<typeof Drawer>();
    const paginationRef = ref<typeof Pagination>();

    //Drawer
    const openDrawer = () => {
      drawerRef.value?.openDrawer();
    };
    const closeDrawer = () => {
      drawerRef.value?.closeDrawer();
    };

    //Pagination
    const nextPage = () => {
      paginationRef.value?.nextPage();
    };
    const previusPage = () => {
      paginationRef.value?.previusPage();
    };

    return { drawerRef, openDrawer, closeDrawer, nextPage, previusPage };
  },

  data(): IData {
    return {
      studentsResults: undefined,
      searchInput: "",
      selectedStudent: undefined,
      drawerValues: {
        estudante_ra: 0,
        valor_credito: "",
        motivo: "",
      },
      loading: false,
      postLoading: false,
      paginationProps: {
        page: 1,
      },
    };
  },

  methods: {
    openDrawerComponent() {
      this.openDrawer();
    },

    selectStudent(values: IStudent) {
      this.selectedStudent = values;
      this.drawerValues.estudante_ra = values.estudante_ra;
      this.openDrawerComponent();
    },

    resetForm() {
      this.selectedStudent = undefined;
      this.drawerValues.estudante_ra = 0;
      this.drawerValues.valor_credito = "";
      this.drawerValues.motivo = "";
    },

    mask(value: string) {
      if (value === "" || value === undefined) {
        return "";
      }

      const numericValue = this.drawerValues.valor_credito.replace(/\D/g, "");
      const limitedValue = numericValue.substring(0, 11);

      return limitedValue;
    },

    appyMask(value: string) {
      const result = this?.mask(value)
        ?.replace(/\D/g, "")
        .replace(/(\d{1,2})$/, `.$1`)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.00");

      return (this.drawerValues.valor_credito = result);
    },

    fetchStudents(page: number, nextPrevius?: string) {
      if (this.searchInput) {
        this.loading = true;

        const geStudents = async () => {
          const response = await FoodAPI.get(
            `estudante_credito/?nome_aluno=${this.searchInput}&page=${page}`,
            {
              headers: {
                "alimentacao-api-key": `${process.env.GET_ALIMENTACAO_ESTUDANTE_CREDITO_KEY}`,
                authorization: `bearer ${JSON.parse(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  localStorage.getItem("access")!
                )}`,
              },
            }
          );

          if (response.status === 200 && response.data) {
            this.studentsResults = response.data;
            this.loading = false;

            if (nextPrevius === "next") {
              this.paginationProps.page += 1;
            } else if (nextPrevius === "previus") {
              this.paginationProps.page -= 1;
            }

            if (page === 1) {
              this.paginationProps.page = 1;
            }
          } else {
            toast.error(
              "Erro ao buscar estudantes. Tente novamente mais tarde!"
            );
            this.loading = false;
          }
        };

        geStudents();
      } else {
        toast.warning("Preencha o formulário.");
      }
    },

    saveCredit() {
      this.postLoading = true;

      if (this.drawerValues && this.drawerValues.valor_credito) {
        const saveCredit = async () => {
          const response = await FoodAPI.post(
            "estudante_credito/",
            {
              estudante_ra: this.drawerValues.estudante_ra,
              valor_credito: this.drawerValues.valor_credito,
              motivo: this.drawerValues.motivo,
            },
            {
              headers: {
                "alimentacao-api-key": `${process.env.POST_ALIMENTACAO_ESTUDANTE_CREDITO_KEY}`,
                authorization: `bearer ${JSON.parse(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  localStorage.getItem("access")!
                )}`,
              },
            }
          );


          if (response.status === 200 && response.data) {
            toast.success("Sucesso ao inserir crédito para o estudante.");
            this.fetchStudents(this.paginationProps.page);
            this.postLoading = false;
            this.resetForm();
            this.closeDrawer();
          } else {
            toast.error("Erro ao inserir crédito. Tente novamente mais tarde!");
            this.postLoading = false;
          }
        };
        saveCredit();
      }
    },
  },

  computed: mapState(["loadAccess"]),

  mounted() {
    store.dispatch("loadAccess");
  },
});
</script>

<style lang="scss">
.foodCredit {
  width: 1024px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px 0;

  h2 {
    margin: none;
    color: $purple;

    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 8px 0;

    button {
      margin-left: auto;
    }
  }
  .input-text {
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    input {
      height: 25px;

      border: none;
      border: 2px solid $light_grey;

      color: $grey;

      font-size: 14px;
      padding: 4px;

      outline: none;

      &:disabled {
        background-color: $light_grey;
        color: $light_grey3;
        cursor: not-allowed;
      }
    }
  }

  .studentContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 16px;
    flex-wrap: wrap;

    width: 100%;

    .studentCard {
      display: flex;
      flex-direction: column;

      border-radius: 24px;
      background: var(--primary-roxo-light-90, $light_purple);

      padding: 10px;
      width: 300px;

      cursor: pointer;

      h4 {
        margin: 0;
        text-align: center;

        font-size: 18px;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .drawer-style {
    display: flex;
    flex-direction: column;

    gap: 8px;

    div {
      display: flex;
      button {
        position: fixed;
        bottom: 16px;
        right: 50px;
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1024px) {
  .foodCredit {
    width: 1000px;
  }
}

@media (max-width: 768px) {
  .foodCredit {
    width: 730px;
  }
}

@media (max-width: 425px) {
  .foodCredit {
    width: 320px;
  }
}

@media (max-width: 425px) {
  .foodCredit {
    width: 300px;

    .studentContainer {
      .studentCard {
        width: 280px;
      }
    }
  }
}
</style>
