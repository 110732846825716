<template>
  <div class="feeding-reports">
    <h2>Relatórios de alimentação</h2>

    <div class="select">
      <label>Tipo de busca</label>
      <select required v-model="this.foodType">
        <option :value="null">Selecione...</option>
        <option
          v-for="(option, index) in this.mokiOptions"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>

    <form class="filters" v-if="this.foodType === 1">
      <div class="inputs">
        <div class="select">
          <label>unidade</label>
          <select required v-model="this.formOne.unit">
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(unit, index) in this.units?.results"
              :key="index"
              :value="unit"
            >
              {{ unit.nome }}
            </option>
          </select>
        </div>

        <InputDate
          :label="'Data inicio'"
          :value="this.formOne.initialDate"
          :selectedDate="this.formOne.initialDate"
          @update:selectedDate="handleUpdate($event, 'formOneInitialDate')"
        />
        <InputDate
          :label="'Data Fim'"
          :value="this.formOne.finalDate"
          :selectedDate="this.formOne.finalDate"
          @update:selectedDate="handleUpdate($event, 'formOneFinalDate')"
        />

        <div class="select">
          <label>Status de pagamento</label>
          <select required v-model="this.formOne.paymentStatus">
            <option
              v-for="(status, index) in this.paymentStatus"
              :key="index"
              :value="status.value"
            >
              {{ status.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="buttons">
        <Button
          class="outline"
          @click="handleSubmit('export')"
          :name="'Export'"
          :disabled="this.submit"
        />
        <Button
          @click="handleSubmit"
          :name="'Buscar'"
          :disabled="this.submit"
        />
      </div>
    </form>

    <form
      @submit.prevent="handleSubmit('export')"
      class="filters"
      v-if="this.foodType === 2"
    >
      <div class="inputs">
        <div class="select">
          <label>unidade</label>
          <select required v-model="this.formTwo.unit">
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(unit, index) in this.units?.results"
              :key="index"
              :value="unit"
            >
              {{ unit.nome }}
            </option>
          </select>
        </div>

        <div class="select">
          <label>ano</label>
          <select required v-model="this.formTwo.year">
            <option :value="''">Selecione...</option>
            <option
              v-for="(year, index) in this.years.results"
              :key="index"
              :value="year.value"
            >
              {{ year.label }}
            </option>
          </select>
        </div>

        <div class="select">
          <label>mês de consumo</label>
          <select required v-model="this.formTwo.month">
            <option :value="''">Selecione...</option>
            <option
              v-for="(month, index) in this.months"
              :key="index"
              :value="month.value"
            >
              {{ month.label }}
            </option>
          </select>
        </div>

        <div class="select">
          <label>Status de pagamento</label>
          <select required v-model="this.formTwo.paymentStatus">
            <option
              v-for="(status, index) in this.paymentStatus"
              :key="index"
              :value="status.value"
            >
              {{ status.label }}
            </option>
          </select>
        </div>
      </div>

      <div class="buttons">
        <Button
          class="outline"
          @click="handleSubmit('export')"
          :name="'Export'"
          :disabled="this.submit"
        />
        <Button
          @click="handleSubmit"
          :name="'Buscar'"
          :disabled="this.submit"
        />
      </div>
    </form>

    <div v-if="this.newData === true">
      <Loading />
    </div>

    <div
      v-if="
        this.foodType === 1 && this.singleFoodResults && this.newData === false
      "
      class="table"
    >
      <h4>Resultados</h4>
      <div v-if="this.singleFoodResults">
        <Table
          :columns="this.singleThead"
          :icons="this.icons"
          @changeSequence="changeOrder"
        >
          <tr
            v-for="(single, index) in this.singleFoodResults.results"
            :key="index"
          >
            <td>{{ single.aluno }}</td>
            <td>{{ single.unidade }}</td>
            <td>{{ onlyDate(single.dia_consumo) }}</td>
            <td>{{ onlyDate(single.dia_da_compra) }}</td>
            <td>{{ single.Total }},00</td>

            <td>
              <span v-if="takeItensTable(single, 'Lanche 1')">
                <img src="@/assets/icons/icon_check.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>
              <span v-if="takeItensTable(single, 'Almoço')">
                <img src="@/assets/icons/icon_check.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>
              <span v-if="takeItensTable(single, 'Lanche 2')">
                <img src="@/assets/icons/icon_check.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>
              <span v-if="takeItensTable(single, 'Lanche Extra')">
                <img src="@/assets/icons/icon_check.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>{{ single.tipo_pagamento }}</td>

            <td>
              <span
                class="tag"
                :class="{
                  cancel: single.status_pagamento === 'Transação Cancelada',
                  waiting: single.status_pagamento === 'Transação Pendente',
                }"
              >
                {{ takeName(single.status_pagamento) }}
              </span>
            </td>
          </tr>
        </Table>
      </div>
      <h4 class="no-results" v-if="this.singleFoodResults.results.length === 0">
        Nenhum resultado encontrado
      </h4>
    </div>

    <div
      v-if="
        this.foodType === 2 && this.foodPackageResults && this.newData === false
      "
      class="table"
    >
      <h4>Resultados</h4>
      <div v-if="this.foodPackageResults">
        <Table
          :columns="this.packageThead"
          :icons="this.icons"
          @changeSequence="changeOrder"
        >
          <tr
            v-for="(pack, index) in this.foodPackageResults.food_packages"
            :key="index"
          >
            <td>{{ pack.aluno }}</td>
            <td>{{ pack.unidade }}</td>
            <td>{{ pack.mes_consumo }}</td>
            <td>{{ onlyDate(pack.data_compra) }}</td>
            <td>{{ pack.valor_total }},00</td>

            <td>
              <span v-if="takeItensTable(pack, 'Lanche 1')">
                <img src="@/assets/icons/icon_trace.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>
              <span v-if="takeItensTable(pack, 'Almoço')">
                <img src="@/assets/icons/icon_check.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>
              <span v-if="takeItensTable(pack, 'Lanche 2')">
                <img src="@/assets/icons/icon_check.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>
              <span v-if="takeItensTable(pack, 'Lanche Extra')">
                <img src="@/assets/icons/icon_check.svg" alt="check" />
              </span>
              <span v-else>
                <img src="@/assets/icons/icon_trace.svg" alt="trace" />
              </span>
            </td>

            <td>{{ pack.tipo_pagamento }}</td>

            <td>
              <span
                class="tag"
                :class="{
                  cancel: pack.status_pagamento === 'Transação Cancelada',
                  waiting: pack.status_pagamento === 'Transação Pendente',
                }"
              >
                {{ takeName(pack.status_pagamento) }}
              </span>
            </td>
          </tr>
        </Table>
      </div>
      <h4
        class="no-results"
        v-if="this.foodPackageResults.food_packages.length === 0"
      >
        Nenhum resultado encontrado
      </h4>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue";
// Components
import InputDate from "@/components/InputDate.vue";
import Button from "@/components/Button.vue";
import Table from "@/components/Table.vue";
import Loading from "@/components/Loading.vue";
// Services
import { FoodAPI } from "@/services/api";
// Vuex
import { mapState } from "vuex";
// Store
import store from "@/store";
// Helpers
import { standardDate } from "@/helpers/masks";
import { MONTHS } from "@/helpers/helpers";
// Libs
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

interface ISingle {
  id: number;
  unidade: string;
  responsavel: string;
  email_responsavel: string;
  aluno: string;
  ra: string;
  ciclo: string;
  ano: number;
  Turma: string | null;
  dia_consumo: string;
  dia_da_compra: string;
  alimentacao: {
    name: string;
    valor: number;
  }[];
  Total: number;
  tipo_pagamento: string;
}

interface ISingleFoodResults {
  count: number;
  previous: string;
  next: string;
  results: ISingle[];
}

interface IPackage {
  matricula: number;
  ano: number;
  mes_consumo: string;
  data_compra: string;
  unidade: string;
  responsavel: string;
  guardian_cpf: string;
  guardian_email: string;
  aluno: string;
  student_ra: string;
  ciclo: string;
  turma: string | null;
  valor_total: number;
  pacote: {
    name: string;
    value: number;
  }[];
  status_pagamento: string;
  metodo: number;
  invoice_id: string;
  saved_as_favorite: boolean;
  is_active: boolean;
  tipo_pagamento: string;
}

interface IFoodPackageResults {
  count: number;
  previous: string;
  next: string;
  food_packages: IPackage[];
}

interface IData {
  submit: boolean;
  newData: boolean;
  foodType: number | null;

  formOne: {
    unit?: {
      codcoligada: string;
      codfilial: string;
      nome: string;
    };
    initialDate: string;
    finalDate: string;
    paymentStatus: string;
  };

  formTwo: {
    unit?: {
      codcoligada: string;
      codfilial: string;
      nome: string;
    };
    year: string;
    month: string;
    paymentStatus: string;
  };

  months: { label: string; value: string }[];
  mokiOptions: { value: number; label: string }[];
  foodPackageResults?: IFoodPackageResults;
  singleFoodResults?: ISingleFoodResults;

  singleThead: string[];
  packageThead: string[];
  icons: string[];

  paymentStatus: { label: string; value: string | string[] }[];

  sequence: boolean;
  sequence2: boolean;
}

interface IFormOne {
  codcoligada: string;
  codfilial: string;
  init_date: string;
  end_date: string;
  status_pagamento: string;

  [key: string]: string;
}

interface IFormTwo {
  codcoligada: string;
  codfilial: string;
  mes: string;
  ano: string;
  status_pagamento: string;

  [key: string]: string;
}

export default defineComponent({
  name: "FeedingReports",

  components: {
    InputDate,
    Button,
    Table,
    Loading,
  },

  data(): IData {
    return {
      submit: false,
      newData: false,
      foodType: null,

      formOne: {
        unit: undefined,
        initialDate: "",
        finalDate: "",
        paymentStatus: "",
      },

      formTwo: {
        unit: undefined,
        year: "",
        month: "",
        paymentStatus: "",
      },
      months: MONTHS,
      mokiOptions: [
        {
          value: 1,
          label: "Relatórios de alimentação avulsa",
        },
        {
          value: 2,
          label: "Relatórios de pacote alimentação",
        },
      ],

      foodPackageResults: undefined,
      singleFoodResults: undefined,

      paymentStatus: [
        {
          label: "Todos",
          value: "",
        },
        {
          label: "Pagos",
          value: ["Transação Aprovada"],
        },
        {
          label: "Cancelados",
          value: ["Transação Cancelada"],
        },
      ],

      singleThead: [
        "Aluno(a)",
        "Unidade",
        "Dia de consumo",
        "Data da compra",
        "Valor da compra",
        "Lanche 1",
        "Almoço",
        "Lanche 2",
        "Lanche extra",
        "Pagamento",
        "Status",
      ],

      packageThead: [
        "Aluno(a)",
        "Unidade",
        "Mês de consumo",
        "Data da compra",
        "Valor da compra",
        "Lanche 1",
        "Almoço",
        "Lanche 2",
        "Lanche extra",
        "Pagamento",
        "Status",
      ],

      icons: ["Aluno(a)", "Data da compra"],

      sequence: false,
      sequence2: true,
    };
  },

  computed: mapState(["years", "units", "loadAccess"]),

  mounted() {
    store.dispatch("fetchYearsData");
    store.dispatch("fetchUnitsData");
    this.formOne.initialDate = this.getDateToday();
    this.formOne.finalDate = this.getDateToday();

    store.dispatch("loadAccess");
  },

  methods: {
    changeOrder(values: { id: number; name: string; value: boolean }) {
      if (values.name) {
        if (values.name === "Aluno(a)") {
          this.sequence = !this.sequence;
        } else {
          this.sequence2 = !this.sequence2;
        }

        const filter =
          values.name === "Aluno(a)"
            ? "aluno"
            : this.foodType === 1
            ? "dia_da_compra"
            : "data_compra";

        const data =
          this.foodType === 1
            ? this.singleFoodResults?.results
            : this.foodPackageResults?.food_packages;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?.sort((a: any, b: any) => {
          const order = (
            values.name === "Aluno(a)" ? this.sequence : this.sequence2
          )
            ? 1
            : -1;

          if (values.name === "Aluno(a)") {
            return a[filter].localeCompare(b[filter]) * order;
          } else if (values.name === "Data da compra") {
            return new Date(a[filter]) > new Date(b[filter]) ? order : -order;
          }

          return 0;
        });
      }
    },

    takeName(name: string) {
      switch (name) {
        case "Transação Aprovada":
          return "Pago";
        case "Transação Cancelada":
          return "Cancelada";
        default:
          return "Pendente";
      }
    },

    onlyDate(dataComplete: string) {
      if (dataComplete) {
        const split = dataComplete.split("T");
        const data = split[0];
        return standardDate(data);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    takeItensTable(values: any, item: string) {
      if (values.alimentacao) {
        const foods = values.alimentacao.find(
          (food: { name: string; valor: number }) => food.name === item
        );

        if (foods) {
          return true;
        } else {
          return false;
        }
      }

      if (values.pacote) {
        const foods = values.pacote?.find(
          (food: { name: string; valor: number }) => food.name === item
        );

        if (foods) {
          return true;
        } else {
          return false;
        }
      }
    },

    getDateToday() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    },

    handleUpdateFoodType(value: number) {
      this.foodType = value;
    },

    handleUpdate(value: string, inputUpdate: string) {
      switch (inputUpdate) {
        // Form 1
        case "formOneInitialDate":
          return (this.formOne.initialDate = value);
        case "formOneFinalDate":
          return (this.formOne.finalDate = value);
        // Form 2
        case "formTwoYear":
          return (this.formTwo.year = value);
        default:
          return (this.formTwo.month = value);
      }
    },

    verifyData() {
      if (
        this.formOne.unit &&
        this.formOne.unit.codcoligada &&
        this.formOne.unit.codfilial &&
        this.formOne.initialDate &&
        this.formOne.finalDate &&
        (this.formOne.paymentStatus || this.formOne.paymentStatus === "")
      ) {
        return true;
      } else if (
        this.formTwo.unit &&
        this.formTwo.unit.codcoligada &&
        this.formTwo.unit.codfilial &&
        this.formTwo.year &&
        this.formTwo.month &&
        (this.formTwo.paymentStatus || this.formTwo.paymentStatus === "")
      ) {
        return true;
      } else {
        return false;
      }
    },

    differentiateFoodType() {
      if (this.foodType === 1 && this.formOne.unit) {
        let filtersOne: IFormOne = {
          codcoligada: this.formOne.unit.codcoligada,
          codfilial: this.formOne.unit.codfilial,
          init_date: this.formOne.initialDate,
          end_date: this.formOne.finalDate,
          status_pagamento: this.formOne.paymentStatus,
        };
        return filtersOne;
      } else if (this.foodType === 2 && this.formTwo.unit) {
        let filtersTwo: IFormTwo = {
          codcoligada: this.formTwo.unit.codcoligada,
          codfilial: this.formTwo.unit.codfilial,
          ano: this.formTwo.year,
          mes: this.formTwo.month,
          status_pagamento: this.formTwo.paymentStatus,
        };

        return filtersTwo;
      }
    },

    handleSubmit(submit: string) {
      if (this.verifyData()) {
        this.submit = true;
        if (submit === "export") {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const queryString = Object.keys(this.differentiateFoodType()!)
            .map(
              (key: string) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  this.differentiateFoodType()![key]
                )}`
            )
            .join("&");

          const hash =
            this.foodType === 1
              ? `${process.env.GET_EXPORT_SINGLE_REPORT}`
              : `${process.env.GET_EXPORT_PACKAGE_REPORT}`;

          const url =
            this.foodType === 1
              ? "/food/export_single_food_report/"
              : "/food/export_food_package_report/";

          const fullUrl = queryString ? `${url}?${queryString}` : url;
          FoodAPI.get(fullUrl, {
            responseType: "blob",
            headers: {
              "alimentacao-api-key": hash,
              authorization: `bearer ${JSON.parse(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                localStorage.getItem("access")!
              )}`,
            },
          })
            .then((response) => {
              const fileName =
                this.foodType === 1
                  ? "relatorio_avulso.csv"
                  : "relatorio_pacote.csv";
              const contentType = "text/csv";
              const blob = new Blob([response.data], { type: contentType });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              this.submit = false;
            })
            .catch((error) => {
              toast.error("Erro na requisição");
              console.error("Erro na requisição:", error);
              this.submit = false;
            });
        } else {
          this.submit = true;
          this.newData = true;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const queryString = Object.keys(this.differentiateFoodType()!)
            .map(
              (key: string) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  this.differentiateFoodType()![key]
                )}`
            )
            .join("&");

          const hash =
            this.foodType === 1
              ? `${process.env.GET_SINGLE_FOOD_REPORT}`
              : `${process.env.GET_FOOD_PACKAGE_REPORT}`;

          const url =
            this.foodType === 1
              ? "/food/single_food_report/"
              : "/food/food_package_report/";

          const fullUrl = queryString ? `${url}?${queryString}` : url;
          FoodAPI.get(fullUrl, {
            headers: {
              "alimentacao-api-key": hash,
              authorization: `bearer ${JSON.parse(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                localStorage.getItem("access")!
              )}`,
            },
          })
            .then((response) => {
              if (this.foodType === 1) {
                this.singleFoodResults = response.data;
              } else {
                this.foodPackageResults = response.data;
              }
              this.submit = false;
              this.newData = false;

              this.sequence = false;
              this.changeOrder({ id: 1, name: "Aluno(a)", value: false });
            })
            .catch((error) => {
              toast.error("Erro na requisição");
              console.error("Erro na requisição:", error);
              this.submit = false;
              this.newData = false;
            });
        }
      } else {
        toast.warning("Existem dados pendentes");
      }
    },
  },
});
</script>

<style lang="scss">
.feeding-reports {
  width: 1440px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px 0;

  h2 {
    margin: none;
    color: $purple;

    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .filters {
    display: flex;
    flex-direction: column;

    div.inputs {
      display: flex;
      justify-content: space-between;
      gap: 0 8px;
      div {
        width: 100%;
      }
    }

    div.buttons {
      display: flex;
      gap: 0 8px;
      justify-content: flex-end;

      margin-top: 8px;
    }
  }

  .tag {
    padding: 4px 8px;
    border-radius: 4px;
    background: #3ba550;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    color: #fff;

    &.cancel {
      background-color: #b50303;
    }

    &.waiting {
      color: $grey;
      background-color: #f9c339;
    }
  }
}

@media (max-width: 1440px) {
  .feeding-reports {
    width: 1280px;
  }
}

@media (max-width: 1024px) {
  .feeding-reports {
    width: 960px;
  }
}

@media (max-width: 768px) {
  .feeding-reports {
    width: 750px;
  }
}

@media (max-width: 425px) {
  .feeding-reports {
    width: 320px;

    align-items: center;
    justify-content: center;

    div {
      width: 100%;
    }

    form {
      width: 100%;
    }

    .filters {
      padding: 8px;

      div.inputs {
        flex-direction: column;

        align-items: center;
        div {
          width: 100%;
        }
      }
    }

    .table {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}

@media (max-width: 320px) {
  .feeding-reports {
    width: 310px;
  }
}
</style>
