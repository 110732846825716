<template>
  <button class="button" :type="this.type ?? 'button'">
    {{ this.name }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ButtonComponent",
  props: {
    name: { type: String, required: true },
    type: { type: String, required: false },
  },

  mounted() {
    this.name;
    this.type;
  },
});
</script>

<style lang="scss">
.button {
  width: 100px;
  height: 37px;

  color: #fff;
  padding: 4px 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border: none;
  background-color: $purple;
  text-transform: uppercase;

  cursor: pointer;

  &.outline {
    background-color: white;
    color: $purple;
    border: 2px solid $purple;
  }

  &:disabled {
    background-color: #e5e5e5;
    color: #999;
    cursor: not-allowed;
    border: none;
  }
}

.button:hover {
  background-color: white;
  color: $purple;
  border: 2px solid $purple;

  padding: 2px 6px;

  &.outline {
    background-color: $purple;
    color: #fff;
  }

  &:disabled {
    background-color: $light_grey2;
    color: $light_grey3;
    cursor: not-allowed;
    border-color: $light_grey2;
  }
}
</style>
