export const standardDate = (value: string) => {
  const date = value?.substring(0, 10).split("-").reverse().join("/");
  return date;
};

export const formatMoneyInt = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{1,2})$/, `$1.00`)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};
