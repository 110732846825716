import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AcessButtonOut = _resolveComponent("AcessButtonOut")!
  const _component_AcessButton = _resolveComponent("AcessButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AcessButtonOut, {
      label: 'Relatórios de alimentação',
      link: 'https://lookerstudio.google.com/s/o-_CnzRGcOA'
    }, null, 8, ["link"]),
    _createVNode(_component_AcessButton, {
      label: 'Gerenciar itens de alimentação',
      link: '/gerenciar-itens-de-alimentacao'
    }),
    _createVNode(_component_AcessButton, {
      label: 'Cadastro de dias úteis',
      link: '/cadastro-de-dias-uteis'
    }),
    _createVNode(_component_AcessButton, {
      label: 'Adicionar Crédito para Alimentação',
      link: '/adicionar-credito-alimentacao'
    }),
    _createVNode(_component_AcessButton, {
      label: 'Mudança de senha',
      link: '/resetar-senha'
    })
  ]))
}