import axios from "axios";
import router from "@/router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export const FoodAPI = axios.create({
  baseURL: `${process.env.API_ALIMENTACAO_URL}api/`,
});


FoodAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      toast.error("O token de acesso expirou. Redirecionando para o login.")
      router.push("/login");
    }

    if (error.response.status === 401) {
      toast.error("Acesso inválido. Verifique os dados informados.")
    }

    return Promise.reject(error);
  }
);
