<template>
  <div class="login">
    <form @submit.prevent="login()">
      <img src="@/assets/imgs/Logo.svg" alt="logo" width="264" height="99" />
      <h2>Boas vindas!</h2>

      <div class="input-text">
        <label>Usuário</label>
        <input
          required
          type="email"
          v-model="this.email"
          :placeholder="'nome@email.com'"
        />
      </div>

      <div class="input-text">
        <label>Senha</label>
        <div class="input-password">
          <input
            required
            :type="showPassword ? 'text' : 'password'"
            v-model="this.password"
            :placeholder="'Digite sua senha'"
          />

          <img
            v-if="!showPassword"
            @click="handleShowPassword()"
            src="@/assets/icons/icon_eye.svg"
            alt="hide_password"
          />

          <img
            v-else
            @click="handleShowPassword()"
            src="@/assets/icons/icon_close_eye.svg"
            alt="show_password"
          />
        </div>
      </div>

      <Button :type="'submit'" :name="'Entrar'" :disabled="this.submit" />
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import { FoodAPI } from "@/services/api";
// Libs
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import store from "@/store";

export default defineComponent({
  name: "LoginComponent",
  components: { Button },

  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
    };
  },

  methods: {
    handleShowPassword() {
      if (this.showPassword) {
        this.showPassword = false;
      } else {
        this.showPassword = true;
      }
    },

    login() {
      const login = async () => {
        const response = await FoodAPI.post("login", {
          email: this.email,
          senha: this.password,
        });

        if (response.status === 200 && response.data) {
          store.dispatch("saveAccess", response.data.access_token);
          localStorage.setItem("user", this.email);
          this.$router.push("/");
        } else {
          toast.error("Acesso inválido!");
        }
      };

      login();
    },
  },
});
</script>

<style lang="scss">
.login {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  form {
    margin-top: 123px;
    margin-left: auto;
    margin-right: auto;

    width: 472px;
    height: 400px;

    border: 2px solid $purple;

    display: flex;
    flex-direction: column;
    gap: 16px 0;

    padding: 48px 32px;

    img {
      margin-left: auto;
      margin-right: auto;
    }

    h2 {
      margin: 0;
      margin-left: auto;
      margin-right: auto;
      height: 60px;

      color: $purple;

      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .input-text {
      display: flex;
      flex-direction: column;

      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      input {
        height: 25px;

        border: none;
        border: 2px solid $light_grey;

        color: $grey;

        font-size: 14px;
        padding: 4px;

        outline: none;

        &:disabled {
          background-color: $light_grey;
          color: $light_grey3;
          cursor: not-allowed;
        }
      }
    }

    .input-password {
      display: flex;
      justify-content: center;

      border: 2px solid $light_grey;

      img {
        cursor: pointer;
      }

      input {
        border: none;
        height: 25px;
        width: 90%;
      }
    }

    button {
      width: 100%;
    }
  }
}

@media (max-width: 425px) {
  .login {
    form {
      width: 320px;

      .input-password {
        img {
          width: 25px;
          margin-right: 5px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .login {
    width: 260px;

    form {
      width: 250px;

      h2 {
        font-size: 32px;
      }
    }
  }
}
</style>
