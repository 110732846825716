<template>
  <div class="pagination-style" :key="this.page">
    <button :disabled="this.disablePrevious" @click="previusPage">Anterior</button>
    <span>Página {{ this.page }} de {{ this.lastPage }}</span>
    <button :disabled="this.disableNext" @click="nextPage">Próxima</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaginationComponent",

  props: {
    disablePrevious: { type: Boolean, required: false },
    disableNext: { type: Boolean, required: false },
    page: { type: Number, required: false },
    lastPage: { type: Number, required: false },
  },

  mounted() {
    this.disablePrevious;
    this.disableNext;
    this.page;
    this.lastPage;
  },

  methods: {
    nextPage() {
      this.$emit("nextPage");
    },

    previusPage() {
      this.$emit("previusPage");
    },
  },
});
</script>

<style lang="scss">
.pagination-style {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 8px;

  button:last-of-type {
    cursor: pointer;

    border: none;
    background-color: $purple;

    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    padding: 8px;

    &:hover {
      background-color: #fff;
      border: 2px solid $purple;
      color: $purple;
      padding: 6px;
    }

    &:disabled {
      background-color: #e5e5e5;
      color: #999;
      cursor: not-allowed;
      border: none;
      padding: 6px;
    }
  }

  button:first-of-type {
    cursor: pointer;
    border: 2px solid $purple;

    background-color: #fff;

    color: $purple;

    text-transform: uppercase;
    font-weight: 800;

    height: 37px;
    padding: 8px;

    &:hover {
      border: none;
      background-color: $purple;
      color: #fff;
      padding: 10px;
    }

    &:disabled {
      background-color: #e5e5e5;
      color: #999;
      cursor: not-allowed;
      border: none;
      padding: 10px;
    }
  }
}
</style>
