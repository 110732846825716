<template>
  <div class="reset-password">
    <h2>Mudança de senha</h2>

    <form @submit.prevent="changePassword()">
      <div class="input-text">
        <label>E-mail</label>

        <input
          required
          type="email"
          v-model="this.email"
          :placeholder="'[nome@email.com]'"
        />
      </div>

      <div class="input-text">
        <label>Senha Atual</label>

        <div class="input-password">
          <input
            required
            :type="showLastPassword ? 'text' : 'password'"
            v-model="this.lastPassword"
            :placeholder="'Digite sua senha atual'"
          />

          <img
            v-if="!showLastPassword"
            @click="handleShowLastPassword()"
            src="@/assets/icons/icon_eye.svg"
            alt="hide_password"
          />

          <img
            v-else
            @click="handleShowLastPassword()"
            src="@/assets/icons/icon_close_eye.svg"
            alt="show_password"
          />
        </div>
      </div>

      <div class="input-text">
        <label>Nova Senha</label>

        <div class="input-password">
          <input
            required
            :type="showNewPassword ? 'text' : 'password'"
            v-model="this.newPassword"
            :placeholder="'Digite sua nova senha'"
          />

          <img
            v-if="!showNewPassword"
            @click="handleShowNewPassword()"
            src="@/assets/icons/icon_eye.svg"
            alt="hide_password"
          />

          <img
            v-else
            @click="handleShowNewPassword()"
            src="@/assets/icons/icon_close_eye.svg"
            alt="show_password"
          />
        </div>
      </div>

      <div class="input-text">
        <label>Nova Senha</label>

        <div class="input-password">
          <input
            required
            :type="showNewPassword ? 'text' : 'password'"
            v-model="this.newPasswordAgain"
            :placeholder="'Digite sua nova senha novamente'"
          />

          <img
            v-if="!showNewPassword"
            @click="handleShowNewPassword()"
            src="@/assets/icons/icon_eye.svg"
            alt="hide_password"
          />

          <img
            v-else
            @click="handleShowNewPassword()"
            src="@/assets/icons/icon_close_eye.svg"
            alt="show_password"
          />
        </div>
      </div>

      <Button type="submit" :name="'Salvar'" :disabled="this.submit" />
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import { FoodAPI } from "@/services/api";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineComponent({
  name: "ResetPassword",
  components: {
    Button,
  },

  data() {
    return {
      email: localStorage.getItem("user"),
      lastPassword: "",
      newPassword: "",
      newPasswordAgain: "",

      showLastPassword: false,
      showNewPassword: false,
    };
  },

  methods: {
    handleShowLastPassword() {
      if (this.showLastPassword) {
        this.showLastPassword = false;
      } else {
        this.showLastPassword = true;
      }
    },

    handleShowNewPassword() {
      if (this.showNewPassword) {
        this.showNewPassword = false;
      } else {
        this.showNewPassword = true;
      }
    },

    changePassword() {
      if (this.newPassword === this.newPasswordAgain) {
        const setNewPassword = async () => {
          const response = await FoodAPI.post(
            "reset_password/",
            {
              email: this.email,
              senha_atual: this.lastPassword,
              nova_senha_1: this.newPassword,
              nova_senha_2: this.newPasswordAgain,
            },
            {
              headers: {
                authorization: `bearer ${JSON.parse(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  localStorage.getItem("access")!
                )}`,
              },
            }
          );

          if (response.status === 200) {
            toast.success("Senha alterada com sucesso!");
            this.$router.push("/");
          }
          if (response.status === 401) {
            toast.error("Verifique os dados informados.");
          } else {
            toast.error("Erro ao alterar a senha.");
          }
        };

        setNewPassword();
      } else {
        toast.warning(
          "As senhas fornecidas não correspondem. Por favor, verifique e tente novamente."
        );
      }
    },
  },
});
</script>

<style lang="scss">
.reset-password {
  width: 1024px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  h2 {
    margin: none;
    color: $purple;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    .input-text {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }
      input {
        height: 25px;
        border: none;
        border: 2px solid $light_grey;
        color: $grey;
        font-size: 14px;
        padding: 4px;
        outline: none;
        &:disabled {
          background-color: $light_grey;
          color: $light_grey3;
          cursor: not-allowed;
        }
      }
    }
    .input-password {
      display: flex;
      justify-content: center;
      border: 2px solid $light_grey;
      img {
        cursor: pointer;
        margin-right: 5px;
      }
      input {
        border: none;
        height: 25px;
        width: 100%;
      }
    }
    button {
      margin-left: auto;
    }
  }
}

@media (max-width: 1024px) {
  .reset-password {
    width: 960px;
  }
}

@media (max-width: 768px) {
  .reset-password {
    width: 700px;
  }
}

@media (max-width: 425px) {
  .reset-password {
    width: 320px;
  }
}

@media (max-width: 425px) {
  .reset-password {
    width: 300px;
  }
}
</style>
