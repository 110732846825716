// Config
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// Pages
import HomeView from "../views/HomeView.vue";
import FeedingReports from "../views/FeedingReports.vue";
import ManageFoodItems from "../views/ManageFoodItems.vue";
import RegistrationWorkingDays from "../views/RegistrationWorkingDays.vue";
import FoodCredit from "../views/FoodCredit.vue";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/relatorios-de-alimentacao",
    name: "feedingReports",
    component: FeedingReports,
  },
  {
    path: "/gerenciar-itens-de-alimentacao",
    name: "manageFoodItems",
    component: ManageFoodItems,
  },
  {
    path: "/cadastro-de-dias-uteis",
    name: "registrationWorkingDays",
    component: RegistrationWorkingDays,
  },
  {
    path: "/adicionar-credito-alimentacao",
    name: "foodCredit",
    component: FoodCredit,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/resetar-senha",
    name: "resetPassword",
    component: ResetPassword,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
