<template>
  <div v-if="drawer" class="drawer">
    <div
      class="drawer-container"
      :class="{ 'slide-content': !this.drawer, hidden: this.drawer }"
    >
      <div class="header-drawer">
        <p>
          {{ drawerName }}
        </p>
        <img
          @click="closeDrawer"
          src="@/assets/icons/icon_close.svg"
          alt="close-drawer"
        />
      </div>
      <div class="body"><slot></slot></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultDrawer",

  data() {
    return {
      drawer: false,
    };
  },

  props: {
    drawerName: { type: String, required: false },
  },

  mounted() {
    this.drawerName;
  },
  methods: {
    openDrawer() {
      this.drawer = true;
    },

    closeDrawer() {
      this.drawer = false;
      this.$emit("drawer-closed");
    },
  },
});
</script>

<style lang="scss">
.drawer {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .slide-content {
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out;

    &.hidden {
      transform: translateX(-100%);
    }
  }

  div.drawer-container {
    width: 551px;

    position: absolute;
    right: 0;

    .header-drawer {
      height: 48px;
      background-color: $yelow_secondary;

      display: flex;
      align-items: center;

      padding: 16px;

      p {
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      img {
        cursor: pointer;
      }
    }
    .body {
      background-color: #fff;
      height: 88vh;

      display: flex;
      flex-direction: column;
      padding: 16px;
    }
  }
}

@media (max-width: 425px) {
  .drawer {
    max-width: 425px;

    .slide-content {
      width: 100%;
      height: 100%;
      background-color: lightblue;
      transition: transform 1s ease-in-out;

      &.hidden {
        transform: translateX(-100%);
      }
    }

    div.drawer-container {
      width: 100%;
    }
  }
}
</style>
