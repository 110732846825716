<template>
  <div class="loading-component">
    <div class="circle" />
    <div class="circle" />
    <div class="circle" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingComponent",
});
</script>

<style lang="scss">
.loading-component {
  opacity: 0;
  display: flex;
  position: relative;
  top: 30px;
  transition: opacity 0.3s ease-in;

  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  opacity: 1;

  .circle {
    background-color: $purple;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 5px;
    animation: bounce 0.5s ease-in infinite;
    &:nth-of-type(2) {
      animation-delay: 0.1s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }
    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  }
}
</style>
